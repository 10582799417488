export const dicColumns = [{
  title: '字典名称',
  dataIndex: 'title',
  key: 'title',
  width: 200,
  align: "left",
  ellipsis: true
}, {
  title: '字典描述',
  dataIndex: 'represent',
  key: 'represent',
  width: 200,
  align: "left",
  ellipsis: true
}, {
  title: '字典编码',
  dataIndex: 'code',
  key: 'code',
  width: 200,
  align: "left",
  ellipsis: true
}, {
  title: '是否启用',
  dataIndex: 'flagEnable',
  key: 'flagEnable',
  width: 80,
  align: "center",
  ellipsis: true,
  scopedSlots: {
    customRender: 'flagEnable'
  }
}, {
  title: '操作',
  width: 200,
  dataIndex: "action",
  align: "left",
  scopedSlots: {
    customRender: 'action'
  }
}]


export const dicValueColumns = [{
  title: '字典值名称',
  dataIndex: 'title',
  key: 'title',
  width: 150,
  align: "left",
  ellipsis: true
}, {
  title: '字典值描述',
  dataIndex: 'titleValue',
  key: 'titleValue',
  width: 200,
  align: "left",
  ellipsis: true
}, {
  title: '字典值编码',
  dataIndex: 'dictionaryValueCode',
  key: 'dictionaryValueCode',
  width: 120,
  align: "left",
  ellipsis: true
}, 
// {
//   title: '是否默认',
//   dataIndex: 'isDefault',
//   key: 'isDefault',
//   width: 80,
//   align: "center",
//   ellipsis: true,
//   scopedSlots: {
//     customRender: 'isDefault'
//   }
// },
 {
  title: '是否启用',
  dataIndex: 'flagEnable',
  key: 'flagEnable',
  width: 80,
  align: "center",
  ellipsis: true,
  scopedSlots: {
    customRender: 'flagEnable'
  }
}, {
  title: '字典值排序',
  dataIndex: 'sort',
  key: 'sort',
  width: 100,
  align: "center",
  ellipsis: true
}, {
  title: '操作',
  width: 120,
  dataIndex: "action",
  align: "left",
  scopedSlots: {
    customRender: 'action'
  }
}]


export const PCDColumns = [{
  title: 'ID值',
  dataIndex: 'id',
  key: 'id',
  width: 100,
  align: "left",
  ellipsis: true
}, {
  title: '名称',
  dataIndex: 'label',
  key: 'label',
  width: 150,
  align: "left",
  ellipsis: true
}, {
  title: '类型',
  dataIndex: 'value',
  key: 'value',
  width: 150,
  align: "left",
  ellipsis: true,
  scopedSlots: {
    customRender: "type"
  }
}, {
  title: '父级ID',
  dataIndex: 'pid',
  key: 'pid',
  width: 150,
  align: "left",
  ellipsis: true
}]




export const smsColumns = [{
  title: '模板编号',
  dataIndex: 'temp',
  key: 'temp',
  width: 200,
  align: "left",
  ellipsis: true
}, {
  title: '模板类型',
  dataIndex: 'smsType',
  key: 'smsType',
  width: 100,
  align: "left",
  ellipsis: true,
  customRender: text => text == 1 ? '模板短信' :text == 2 ? '验证码' : ''
},{
  title: '是否管家提醒',
  dataIndex: 'smsSource',
  key: 'smsSource',
  width: 100,
  align: "left",
  ellipsis: true,
  customRender: text => text == 1 ? '是' : '否'
}, {
  title: '是否实时发送',
  dataIndex: 'flagNow',
  key: 'flagNow',
  width: 100,
  align: "center",
  ellipsis: true,
  customRender: text => text ? '是' : '否'
}, {
  title: '模板内容',
  dataIndex: 'smsContent',
  key: 'smsContent',
  width: 250,
  align: "left",
  ellipsis: true
}, {
  title: '创建人',
  dataIndex: 'createUser',
  key: 'createUser',
  width: 150,
  align: "left",
  ellipsis: true
}]
